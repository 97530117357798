import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./logo.svg";
import OTPAuth from "otpauth";
import MenuModal from "./MenuModal";

function App() {
  const [state, setstate] = useState({
    isToken: false,
    token: "---- ----",
    countdown: 30 - (Math.round(new Date().getTime() / 1000.0) % 30),
  });
  useEffect(() => {
    function generateToken() {
      const secret = localStorage.getItem("secret");
      if (!secret) return formatToken("--------");
      const totp = new OTPAuth.TOTP({
        issuer: "iqcode",
        label: "iqcode",
        algorithm: "SHA1",
        digits: 8,
        period: 30,
        secret: secret,
      });
      return formatToken(totp.generate());
    }

    const formatToken = (token) => {
      return token.slice(0, 4) + " " + token.slice(4);
    };
    let secret = localStorage.getItem("secret");
    if (!!secret) {
      setstate((state) => {
        return {
          ...state,
          token: generateToken(),
          isToken: true,
        };
      });
    }
    const intervalId = setInterval(() => {
      setstate((state) => {
        if (state.countdown === 0) {
          if (state.isToken) {
            return {
              ...state,
              token: generateToken(),
              countdown: 30,
            };
          } else {
            return {
              ...state,
              countdown: 30,
            };
          }
        } else {
          if (state.isToken && state.token === "---- ----") {
            return {
              ...state,
              token: generateToken(),
              countdown: state.countdown - 1,
            };
          }
          return { ...state, countdown: state.countdown - 1 };
        }
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <>
      <div className="overlay-container">
        <div className="App">
          <MenuModal
            secretChanged={(secret) => {
              localStorage.setItem("secret", secret);
              setstate({ ...state, isToken: true });
            }}
          ></MenuModal>
          <header className="App-header">
            <img
              src={logo}
              style={{ width: "50%", maxHeight: "25vh", marginBottom: "50px" }}
              alt="logo"
            />
            <div
              id="token"
              style={{
                marginTop: "6px",
                background: "rgb(27, 31, 38)",
                padding: "12px",
                borderRadius: "8px",
                display: "block",
                width: "50%",
                color:
                  state.countdown === 0
                    ? "rgb(27, 31, 38)"
                    : state.countdown > 4
                    ? "white"
                    : "#f4027a",
                fontSize: "30px",
                fontWeight: 500,
                boxShadow:
                  "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2)",
              }}
            >
              {state.token}
              <div className="progress-bar">
                <div
                  className="progress-bar-progress"
                  style={{
                    backgroundColor:
                      state.countdown === 0
                        ? "white"
                        : state.countdown > 4
                        ? "#03a9f4"
                        : "#f4027a",
                    width: (state.countdown * 100.0) / 30.0 + "%",
                    transition:
                      state.countdown === 30 ? "1s ease" : "1s linear",
                  }}
                ></div>
              </div>
            </div>
          </header>
        </div>
      </div>
    </>
  );
}

export default App;
