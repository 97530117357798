import React, { useState, useEffect } from "react";

function MenuModal(props) {
  const [state, setState] = useState({
    isOpen: false,
    secret: "",
  });
  useEffect(() => {}, []);
  return (
    <>
      {state.isOpen && (
        <div className="modal" style={{fontFamily: "Montserrat"}}>
          <div className="modal-inner">
            <div className="modal-form">
              <p>Wprowadź nowy kod autoryzacyjny:</p>
              <input
                placeholder="Kod autoryzacyjny"
                type="text"
                onChange={(e) => {
                  setState({
                    ...state,
                    secret: e.target.value.replace(/[^a-zA-Z]+/, ""),
                  });
                }}
                value={state.secret}
              ></input>
              <button
                className={
                  state.secret.length <= 7 ? "primary disabled" : "primary"
                }
                onClick={() => {
                  if (state.secret.length >= 8) {
                    const secret = state.secret;
                    setState({ ...state, isOpen: false, secret: "" });
                    props.secretChanged(secret);
                  }
                }}
              >
                Zaktualizuj &#10003;
              </button>
              <button
                onClick={() => {
                  setState({ ...state, isOpen: false });
                }}
              >
                Anuluj &#8617;
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        className="modal-btn"
        onClick={() => {
          setState({ ...state, isOpen: !state.isOpen });
        }}
      >
        &#9776;
      </div>
    </>
  );
}

export default MenuModal;
